// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.portal.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { AppEnvironment } from '../app/app-environment';
import packageJson from '../../package.json'
export const environment: AppEnvironment = {
  production: true,
  adminApiBaseUrl: '#{BsiConnect--Services--ApiAdminBaseUrl}#',
  authApiBaseUrl: '#{BsiConnect--Services--ApiAuthBaseUrl}#',
  documentApiBaseUrl: '#{BsiConnect--Services--ApiDocumentBaseUrl}#',
  storageApiBaseUrl: '#{BsiConnect--Services--ApiStorageBaseUrl}#',
  authority: '#{azureb2c-host}#/#{azureb2c-tenant}#/#{AzureAdB2C--SignUpSignInPolicyId}#',
  knownAuthorities: ['#{azureb2c-host}#'],
  redirectUrl: '#{BsiConnect--Services--PortalRedirectUrl}#',
  authApiScope: 'https://#{azureb2c-tenant}#/auth-api/all',
  buildId: '#{Build.BuildId}#',
  buildNumber: '#{Build.BuildNumber}#',
  clientId: '#{BsiConnect--Services--ClientId-Portal}#',
  pipeline: '#{Build.DefinitionName}#',
  branch: '#{Build.SourceBranch}#',
  stage: '#{drz}#',
  repository: '#{Build.Repository.Name}#',
  commit: '#{Build.SourceVersion}#',
  environmentId: '#{Environment.Id}#',
  environmentName: '#{Environment.Name}#',
  version: packageJson.version,
  workspaceUrl: '#{BsiConnect--Services--WorkspaceUrl}#',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

